/**
 * @file Handles timezone-related operations.
 * */

import { setCookie } from '@@/bits/cookie'
import jstz from 'jstz'

function setTimezoneCookie(): void {
  const timeZone = jstz.determine().name()
  setCookie('ww_tz', timeZone)
}

/**
 * Returns the timezone name of the local window in the format of options.timeZone.
 * Usage: new Date().toLocaleString('en-GB', { timeZone: getTimezoneName() })
 *
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 *
 * @returns The local window timezone in the format of options.timeZone
 */
function getTimezoneName(): string {
  return jstz.determine().name()
}

export { getTimezoneName, setTimezoneCookie }
